<template>
    <div class="common-layout">
      <el-container>
        <el-header style="border: 1px solid red;background-color: #2561ef;color:white;text-align: center;height: 60px;line-height: 60px;">
            <el-row>
                <el-col :span="3"><div class="grid-content ep-bg-purple" />OA办公后台管理系统</el-col>
                <el-col :span="21"><div class="grid-content ep-bg-purple-light" />

                    <el-menu
                        :default-active="activeIndex"
                        background-color ="#2561ef"
                        text-color="#ffffff"
                        active-text-color="#fff"
                        class="el-menu-demo continer"
                        mode="horizontal"
                        @select="handleSelect"
                    >
                        <router-link to="/">
                        <el-menu-item style="width: 100px;" class="elIcon"  index="/homePageEl">
                                <el-icon><HomeFilled /></el-icon>
                                <span>首页</span>
                        </el-menu-item>
                        </router-link>
                        <!-- <el-button  ref="buttonRef" v-click-outside="onClickOutside"> -->
                        <el-menu-item  class="elIcon" index="/organizationOrganization" ref="buttonRef" v-click-outside="onClickOutside">
                                <el-icon><Grid /></el-icon>  
                            <span>应用中心</span>
                        </el-menu-item>
                      <!-- </el-button> -->
                      <el-popover
                        ref="popoverRef"
                        :virtual-ref="buttonRef"
                        trigger="click"
                        virtual-triggering
                        :show-arrow="false"
                        width="480px"
                        height="390px"
                        border-radius="10px"
                      >
                        <div class="centerOne" >
                          <router-link class="tab_item" to="/organizationOrganization">
                            <div class="centerTwo">
                                <div class="centerThree"><img src="../assets/images/topOrganization.png"/></div>
                                <div class="centerfour">组织</div>
                            </div>
                          </router-link>
                          
                          <router-link class="tab_item" to="/employeeEmployee">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topYG.png"/></div>
                              <div class="centerfour">员工</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/timeKeeping">
                          <div class="centerTwo"  to="/organizationOrganization">
                              <div class="centerThree"><img src="../assets/images/topKQ.png"/></div>
                              <div class="centerfour">考勤</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topXC.png"/></div>
                              <div class="centerfour">薪酬</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topSB.png"/></div>
                              <div class="centerfour">社保公积金</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topJX.png"/></div>
                              <div class="centerfour">绩效</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topZP.png"/></div>
                              <div class="centerfour">招聘</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/approvalApproval">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topSP.png"/></div>
                              <div class="centerfour">审批</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topHYS.png"/></div>
                              <div class="centerfour">会议室预定</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topCL.png"/></div>
                              <div class="centerfour">车辆</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topXW.png"/></div>
                              <div class="centerfour">新闻</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topGG.png"/></div>
                              <div class="centerfour">公告</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topHTK.png"/></div>
                              <div class="centerfour">合同库</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topWDK.png"/></div>
                              <div class="centerfour">文档库</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/assetAsset">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topZC.png"/></div>
                              <div class="centerfour">资产</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo">
                              <div class="centerThree"><img src="../assets/images/topPX.png"/></div>
                              <div class="centerfour">培训</div>
                          </div>
                          </router-link>

                          <router-link class="tab_item" to="/">
                          <div class="centerTwo" style="margin-right:267px;">
                              <div class="centerThree"><img src="../assets/images/topXTSZ.png"/></div>
                              <div class="centerfour">系统设置</div>
                          </div>
                          </router-link>
                        </div>
                      </el-popover>
                        <el-input
                        v-model="input2"
                        style="width: 400px;height: 40px;"
                        placeholder="请输入关键字"
                        :prefix-icon="Search"
                        />

                    </el-menu>
                </el-col>
            </el-row>
        </el-header>
      </el-container>
    </div>
</template>
  

<script setup name="topTop">
import { ref,unref } from 'vue'
import { Search,HomeFilled,Grid,} from '@element-plus/icons-vue'
const input2 = ref('') 
import { ClickOutside as vClickOutside } from 'element-plus'

const buttonRef = ref()
const popoverRef = ref()
const onClickOutside = () => {
  unref(popoverRef).popperRef?.delayHide?.()
}
</script>

<style scoped lang="scss">
.elIcon {  
  display: flex;  
  flex-direction: column;  
  align-items: center; /* 使图标和文字水平居中 */  
  justify-content: center; /* 使图标和文字垂直居中 */  
}  
  
.elIcon .el-icon {
  margin-top:15px;  
  margin-left: 6px;
  margin-bottom: -15px; /* 图标和文字之间的间距 */  
} 
.el-menu-demo {  
  display: flex;  
//   justify-content: center; /* 水平居中 */  
  align-items: center; /* 垂直居中，如果需要的话 */  
//   height: 100vh; /* 设置容器高度为视口高度，以便垂直居中 */  
}
.centerOne{
  height: 350px;
  border-radius: 10px;
  margin: 8px;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .centerTwo{
    width: 80px;
    height: 80px;
    border-radius: 4px;
    .centerThree{
      width: 80px;
      height: 26px;
      padding: 12px 0px 8px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .centerfour{
      width: 80px;
      height: 14px;
      padding: 5px 0px 12px 0px;
      font-family: 'PingFangSC-Regular', 'PingFang SC';
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .centerTwo:hover{
    background-color: rgba(37,97,239,0.078);
  }
}
.tab_item{
  text-decoration: none;
  color: #333333;
}
</style>
