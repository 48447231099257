<template>
  <div>
    <topTop></topTop>
    <!-- <LoginFrame></LoginFrame> -->
    <!-- <HomePageEl></HomePageEl> -->
    <!-- <router-view></router-view> -->
    <!-- <organizationOrganization></organizationOrganization> -->
    <!-- <employeeEmployee></employeeEmployee> -->
     <!-- <timeKeeping></timeKeeping> -->
      <router-view></router-view>
  </div>
</template>

<script>
import topTop from '../src/components/topTop.vue'
// import LoginFrame from '../src/components/login/LoginFrame.vue'
// import HomePage from '../src/components/homePage/HomePage.vue'
// import HomePageEl from '../src/components/homePage/homePageEl.vue'
// import organizationOrganization from '../src/components/organization/organizationOrganization.vue'
// import employeeEmployee from '../src/components/employee/employeeEmployee.vue'
// import timeKeeping from '../src/components/timekeeping/timeKeeping.vue'
export default {
  name: 'App',
  components:{
    topTop,
    // LoginFrame,
    // HomePageEl,
    // organizationOrganization,
    // employeeEmployee,
    // timeKeeping,
  },
}

</script>

<style>
body{
  margin:0px;
}
</style>
