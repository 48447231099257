import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  // },
  {
    path: '/',
    // 通讯录
    name: 'homePageEl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/components/homePage/homePageEl.vue')
  },
  {
    path: '/organizationOrganization',
    // 通讯录
    name: 'organizationOrganization',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/components/organization/organizationOrganization.vue'),
    children:[
      {
        path: '/addressBook',
        // 通讯录
        name: 'addressBook',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/organizations/addressBook.vue'),
      },
      {
        path: '/newEmployee',
        // 新增员工
        name: 'newEmployee',
        component: () => import('@/components/organization/right/organizations/second/newEmployee.vue')
      },
      {
        path: '/newOrganization',
        // 新增组织
        name: 'newOrganization',
        component: () => import('@/components/organization/right/organizations/second/newOrganization.vue')
      },
      {
        path: '/organizationalManagement',
        // 组织管理
        name: 'organizationalManagement',
        component: () => import('@/components/organization/right/organizations/organizationalManagement.vue')
      },
      {
        path: '/workLocation',
        // 工作地点
        name: 'workLocation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/organizations/workLocation.vue')
      },
      {
        path: '/positionManagement',
        // 职位管理
        name: 'positionManagement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/position/positionManagement.vue')
      },
      {
        path: '/JobManagement',
        // 职务管理
        name: 'JobManagement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/position/JobManagement.vue')
      },
      {
        path: '/rankManagement',
        // 职级管理
        name: 'rankManagement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/position/rankManagement.vue')
      },
      {
        path: '/jobCategory',
        // 职位类别
        name: 'jobCategory',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/position/jobCategory.vue')
      },
      {
        path: '/roleManagement',
        // 角色管理
        name: 'roleManagement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/rose/roleManagement.vue')
      },
      {
        path: '/organizationalType',
        // 组织类型
        name: 'organizationalType',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/set/organizationalType.vue')
      },
      {
        path: '/BookSetting',
        // 通讯录字段设置
        name: 'BookSetting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/organization/right/set/BookSetting.vue')
      },
    ],
    redirect:'/addressBook',
  },

  {
    path: '/employeeEmployee',
    // 1-1员工-花名册
    name: 'employeeEmployee',
    component: () => import('@/components/employee/employeeEmployee.vue'),
    children:[
      {
        path: '/rightRight',
        // 1-1员工-花名册
        name: 'rightRight',
        component: () => import('@/components/employee/rightRight.vue')
      },
      {
        path: '/newEmployee',
        // 新增员工
        name: 'newEmployee',
        component: () => import('@/components/organization/right/organizations/second/newEmployee.vue')
      },
      {
        path: '/resignationProcess',
        // 办理离职
        name: 'resignationProcess',
        component: () => import('@/components/employee/right/employeeRelationship/second/resignationProcess.vue')
      },
      {
        path: '/confirmResignation',
        // 确认离职
        name: 'confirmResignation',
        component: () => import('@/components/employee/right/employeeRelationship/second/confirmResignation.vue')
      },
      {
        path: '/signAcontract',
        // 签订合同
        name: 'signAcontract',
        component: () => import('@/components/employee/right/employeeRelationship/second/signAcontract.vue')
      },
      {
        path: '/renewTheContract',
        // 续签合同
        name: 'renewTheContract',
        component: () => import('@/components/employee/right/employeeRelationship/second/renewTheContract.vue')
      },
      {
        path: '/employeeFiles',
        // 1-2员工-员工档案
        name: 'employeeFiles',
        component: () => import('@/components/employee/right/employeeManagement/employeeFiles.vue')
      },
      {
        path: '/addingEducationalExperience',
        // 1-2员工-员工档案-新增教育经历
        name: 'addingEducationalExperience',
        component: () => import('@/components/employee/right/employeeManagement/addingEducationalExperience.vue')
      },
    
      {
        path: '/onboardingManagement',
        //2-1员工-入职管理 
        name: 'onboardingManagement',
        component: () => import('@/components/employee/right/employeeRelationship/onboardingManagement.vue')
      },
      {
        path: '/probationManagement',
        // 2-2员工-转正管理
        name: 'probationManagement',
        component: () => import('@/components/employee/right/employeeRelationship/probationManagement.vue')
      },
      {
        path: '/personnelTransfer',
        //2-3员工-人事调动 
        name: 'personnelTransfer',
        component: () => import('@/components/employee/right/employeeRelationship/personnelTransfer.vue')
      },
      {
        path: '/resignationManagement',
        //2-4员工-离职管理 
        name: 'resignationManagement',
        component: () => import('@/components/employee/right/employeeRelationship/resignationManagement.vue')
      },
      {
        path: '/recordOfPersonnel',
        //2-5员工-异动记录 
        name: 'recordOfPersonnel',
        component: () => import('@/components/employee/right/employeeRelationship/recordOfPersonnel.vue')
      },
      {
        path: '/contractManagement',
        //2-6员工-合同管理 
        name: 'contractManagement',
        component: () => import('@/components/employee/right/employeeRelationship/contractManagement.vue')
      },
      {
        path: '/trendAnalysis',
        //3-2趋势分析 
        name: 'trendAnalysis',
        component: () => import('@/components/employee/right/statisticalAnalysis/trendAnalysis.vue')
      },
      {
        path: '/onTheJob',
        //3-3在职员工分析 
        name: 'onTheJob',
        component: () => import('@/components/employee/right/statisticalAnalysis/onTheJob.vue')
      },
      {
        path: '/joiningTheCompany',
        //3-4入职员工分析
        name: 'joiningTheCompany',
        component: () => import('@/components/employee/right/statisticalAnalysis/joiningTheCompany.vue')
      },
      {
        path: '/leavingTheCompany',
        //3-5离职员工分析 
        name: 'leavingTheCompany',
        component: () => import('@/components/employee/right/statisticalAnalysis/leavingTheJob.vue')
      },
      {
        path: '/positionChange',
        //3-6员工调动分析 
        name: 'positionChange',
        component: () => import('@/components/employee/right/statisticalAnalysis/positionChange.vue')
      },
      {
        path: '/trendAnalysis',
        //3-6员工调动分析 
        name: 'trendAnalysis',
        component: () => import('@/components/employee/right/statisticalAnalysis/trendAnalysis.vue')
      },
      {
        path: '/fileFile',
        //4-1
        name: 'fileFile',
        component: () => import('@/components/employee/right/employeeSetup/fileFile.vue')
      },
      {
        path: '/formForm',
        //4-2
        name: 'formForm',
        component: () => import('@/components/employee/right/employeeSetup/formForm.vue')
      },
      {
        path: '/ruleRule',
        //4-3
        name: 'ruleRule',
        component: () => import('@/components/employee/right/employeeSetup/ruleRule.vue')
      },
    ],
    redirect:'/rightRight'
  },
  {
    path:'/timeKeeping',
    name:'timeKeeping',
    component:()=>import('@/components/timekeeping/timeKeeping.vue'),
    redirect:'/attendanceManagementGroup',
    children:[
    {
      path:'/attendanceManagementGroup',
      //1-1考勤组管理
      name:'attendanceManagementGroup',
      component:()=>import('@/components/timekeeping/right/attendanceManagementOne/attendanceManagementGroup.vue')
    },
    {
      path:'/addAttendanceGroup',
      //1-1-1新增考勤组
      name:'addAttendanceGroup',
      component:()=>import('@/components/timekeeping/right/second/addAttendanceGroup.vue')
    },
    {
      path:'/newOvertimeRules',
      //1-1-2新增加班规则
      name:'newOvertimeRules',
      component:()=>import('@/components/timekeeping/right/second/newOvertimeRules.vue')
    },
    {
      path:'/newScheduling',
      //1-1-3新增班次
      name:'newScheduling',
      component:()=>import('@/components/timekeeping/right/second/newScheduling.vue')
    },
    {
      path:'/newHolidayRules',
      //1-1-4新增假期规则
      name:'newHolidayRules',
      component:()=>import('@/components/timekeeping/right/second/newHolidayRules.vue')
    },
    {
      path:'/shiftManagement',
      //1-2班次管理
      name:'shiftManagement',
      component:()=>import('@/components/timekeeping/right/attendanceManagementOne/shiftManagement.vue')
    },
    {
      path:'/schedulingManagement',
      //1-3排班管理
      name:'schedulingManagement',
      component:()=>import('@/components/timekeeping/right/attendanceManagementOne/schedulingManagement.vue')
    },
    {
      path:'/timekeepingRegulations',
      //1-4考勤规则
      name:'timekeepingRegulations',
      component:()=>import('@/components/timekeeping/right/attendanceManagementOne/timekeepingRegulations.vue')
    },
    {
      path:'/timekeepingMethod',
      //1-5打卡方式
      name:'timekeepingMethod',
      component:()=>import('@/components/timekeeping/right/attendanceManagementOne/timekeepingMethod.vue')
    },
    {
      path:'/monthlyAttendanceSheet',
      //1-1月考勤表
      name:'monthlyAttendanceSheet',
      component:()=>import('@/components/timekeeping/right/attendanceStatisticsTwo/monthlyAttendanceSheet.vue')
    },
    {
      path:'/dailyStatistics',
      //1-2每日统计
      name:'dailyStatistics',
      component:()=>import('@/components/timekeeping/right/attendanceStatisticsTwo/dailyStatistics.vue')
    },
    {
      path:'/attendanceRecords',
      //1-3打卡记录
      name:'attendanceRecords',
      component:()=>import('@/components/timekeeping/right/attendanceStatisticsTwo/attendanceRecords.vue')
    },
    {
      path:'/holidayRules',
      //3-1假期规则
      name:'holidayRules',
      component:()=>import('@/components/timekeeping/right/vacationManagementThree/holidayRules.vue')
    },
    {
      path:'/holidayBalance',
      //3-2假期余额
      name:'holidayBalance',
      component:()=>import('@/components/timekeeping/right/vacationManagementThree/holidayBalance.vue')
    },
    {
      path:'/settingsSet',
      //3-2假期余额
      name:'settingsSet',
      component:()=>import('@/components/timekeeping/right/setFour/settingsSet.vue')
    },
    ]
  },
  {
    path:'/approvalApproval',
    name:'approvalApproval',
    component:()=>import('@/components/approval/approvalApproval.vue'),
    redirect:'/approvalManagement',
    children:[
    {
      path:'/approvalManagement',
      //1-1审批管理
      name:'approvalManagement',
      component:()=>import('@/components/approval/right/approvalManagement.vue')
    },
    {
      path:'/approvalDetails',
      //1-1审批详情
      name:'approvalDetails',
      component:()=>import('@/components/approval/approvalDetails.vue')
    },
    {
      path:'/createNewForm',
      //1-3新建表单
      name:'createNewForm',
      component:()=>import('@/components/approval/right/childComponent/createNewForm.vue')
    },
    {
      path:'/approvalForm',
      //1-2审批表单
      name:'approvalForm',
      component:()=>import('@/components/approval/right/approvalForm.vue'),
      redirect:'/humanResources',
      children:[
        {
          path:'/humanResources',
          //人事
          name:'humanResources',
          component:()=>import('@/components/approval/right/childComponent/humanResources.vue'),
        },
        {
          path:'/attendanceManagement',
          //考勤
          name:'attendanceManagement',
          component:()=>import('@/components/approval/right/childComponent/attendanceManagement.vue'),
        },
        {
          path:'/administrationAdministration',
          //行政
          name:'administrationAdministration',
          component:()=>import('@/components/approval/right/childComponent/administrationAdministration.vue'),
        },
        {
          path:'/financeFinance',
          //行政
          name:'financeFinance',
          component:()=>import('@/components/approval/right/childComponent/financeFinance.vue'),
        },
      ]
    },
    ]
  },
  {
    path:'/assetAsset',
    //资产
    name:'assetAsset',
    component:()=>import('@/components/asset/assetAsset.vue'),
    redirect:'/assetList',
    children:[
      {
        path:'/assetList',
        //1-1资产管理-资产列表
        name:'assetList',
        component:()=>import('@/components/asset/right/assetManagementOne/assetList.vue'),
      },
      {
        path:'/newlyAddedAssets',
        //1-1资产管理-资产列表-新增资产
        name:'newlyAddedAssets',
        component:()=>import('@/components/asset/right/assetManagementOne/second/newlyAddedAssets.vue'),
      },
      {
        path:'/assetClass',
        //1-2资产管理-资产类别
        name:'assetClass',
        component:()=>import('@/components/asset/right/assetManagementOne/assetClass.vue'),
      },
      {
        path:'/warehouseManagement',
        //1-3资产管理-仓库管理
        name:'warehouseManagement',
        component:()=>import('@/components/asset/right/assetManagementOne/warehouseManagement.vue'),
      },
      {
        path:'/assetInventory',
        //1-4资产管理-资产盘点
        name:'assetInventory',
        component:()=>import('@/components/asset/right/assetManagementOne/assetInventory.vue'),
      },
      {
        path:'/inventoryOfNewAdditions',
        //1-4资产管理-资产盘点-新增盘点
        name:'inventoryOfNewAdditions',
        component:()=>import('@/components/asset/right/assetManagementOne/second/inventoryOfNewAdditions.vue'),
      },
      {
        path:'/purchaseOrder',
        //2-1库存管理-采购单
        name:'purchaseOrder',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/purchaseOrder.vue'),
      },
      {
        path:'/newPurchaseOrder',
        //2-1-1库存管理-采购单-新增采购单
        name:'newPurchaseOrder',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/second/newPurchaseOrder.vue'),
      },
      {
        path:'/assetStocktaking',
        //2-2库存管理-资产入库
        name:'assetStocktaking',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/assetStocktaking.vue'),
      },
      {
        path:'/confirmReceipt',
        //2-2-2库存管理-资产入库-确认入库
        name:'confirmReceipt',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/second/confirmReceipt.vue'),
      },
      {
        path:'/obtainForUse',
        //2-3库存管理-领用
        name:'obtainForUse',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/obtainForUse.vue'),
      },
      {
        path:'/requisitionApplication',
        //2-3-1库存管理-领用-快速领用申请
        name:'requisitionApplication',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/second/requisitionApplication.vue'),
      },
      {
        path:'/returnToInventory',
        //2-4库存管理-退库
        name:'returnToInventory',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/returnToInventory.vue'),
      },
      {
        path:'/applicationForInventoryReturn',
        //2-4-1库存管理-退库-快速退库申请
        name:'applicationForInventoryReturn',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/second/applicationForInventoryReturn.vue'),
      },
      {
        path:'/borrowBorrow',
        //2-5库存管理-借用
        name:'borrowBorrow',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/borrowBorrow.vue'),
      },
      {
        path:'/applicationForBorrowing',
        //2-5库存管理-借用-新增借用申请
        name:'applicationForBorrowing',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/second/applicationForBorrowing.vue'),
      },
      {
        path:'/returnReturn',
        //2-6库存管理-归还
        name:'returnReturn',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/returnReturn.vue'),
      },
      {
        path:'/repayRepay',
        //2-6-1库存管理-归还-快速归还申请
        name:'repayRepay',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/second/repayRepay.vue'),
      },
      {
        path:'/transferTranfer',
        //2-6库存管理-调拨
        name:'transferTranfer',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/transferTranfer.vue'),
      },
      {
        path:'/transferApplication',
        //2-6-1库存管理-调拨-快速调拨申请
        name:'transferApplication',
        component:()=>import('@/components/asset/right/inventoryManagementTwo/second/transferApplication.vue'),
      },
      {
        path:'/overviewOfAssets',
        //3-1统计分析-资产概况
        name:'overviewOfAssets',
        component:()=>import('@/components/asset/right/statisticalAnalysisThree/overviewOfAssets.vue'),
      },
      {
        path:'/InboundInventoryStatistics',
        //3-2统计分析-入库统计
        name:'InboundInventoryStatistics',
        component:()=>import('@/components/asset/right/statisticalAnalysisThree/InboundInventoryStatistics.vue'),
      },
      {
        path:'/outboundStatistics',
        //3-3统计分析-出库统计
        name:'outboundStatistics',
        component:()=>import('@/components/asset/right/statisticalAnalysisThree/outboundStatistics.vue'),
      },
      {
        path:'/inventoryStatistics',
        //3-4统计分析-库存统计
        name:'inventoryStatistics',
        component:()=>import('@/components/asset/right/statisticalAnalysisThree/inventoryStatistics.vue'),
      },
      {
        path:'/inventoryCountStatistics',
        //3-5统计分析-盘点统计
        name:'inventoryCountStatistics',
        component:()=>import('@/components/asset/right/statisticalAnalysisThree/inventoryCountStatistics.vue'),
      },
      {
        path:'/settingSet',
        //4-1资产-设置
        name:'settingSet',
        component:()=>import('@/components/asset/right/settingsFour/settingSet.vue'),
      },
    ],
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
